import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ListResult } from './api-models';
import { UserSimpleDTO } from '../models/user-simple.dto';
import { SalesAdvisorFormDTO } from '../models/sales-advisor-form.dto';
import { DivisionSelectorDTO } from '../models/division-selector.dto';
import { RegionSelectorDTO } from '../models/region-selector.dto';
import { Attachment } from '../models/attachment';

export interface Division {
  _id: string;
  id: string;
  name: string;
  details: string;
}

export interface Region {
  _id: string;
  id: string;
  name: string;
  divisions: Division[];
}

export interface IntroducerLinks {
  referralCode: string;
  qrPng: Attachment;
  qrSvg: Attachment;
  introducerApi: boolean;
  introducerApiKey: string;
}

@Injectable()
export class IntroducerApiService {

  readonly API_NAME = 'introducer';

  constructor(private apiService: ApiService) {}

  public addIntroducer(body) {
    this.removeLogoWithoutFilename(body);
    return this.apiService.put(this.API_NAME, 'addIntroducer', body).toPromise();
  }

  public editIntroducer(id, body) {
    this.removeLogoWithoutFilename(body);
    return this.apiService.post(this.API_NAME, 'editIntroducer', body, {
      params: {
        introducerId: id
      }
    }).toPromise();
  }

  public getAllIntroducersWithContact() {
    return this.apiService.get(this.API_NAME, 'getAllIntroducersWithContact').toPromise();
  }

  public getIntroducerSalesAdvisors(id) {
    return this.apiService.get('salesAdvisor', 'getIntroducerSalesAdvisors', {
      params: {
        introducerId: id
      }
    }).toPromise();
  }

  public async getDivisionSalesAdvisors(introducerId, divisionId): Promise<ListResult<UserSimpleDTO>> {
    const result = await this.apiService.get('salesAdvisor', 'getDivisionSalesAdvisors', {
      params: {
        introducerId: introducerId,
        divisionId: divisionId
      }
    }).toPromise<ListResult<UserSimpleDTO>>();

    return result;
  }

  public getDashboardData(id, fields) {
    return this.apiService.get(this.API_NAME, 'getDashboardData', {
      params: {
        introducerId: id,
        ...fields
      }
    }).toPromise();
  }

  public removeIntroducer(id) {
    return this.apiService.delete(this.API_NAME, 'removeIntroducer', {
      params: {
        introducerId: id
      }
    }).toPromise();
  }

  public getIntroducerDivisions(id: string): Promise<ListResult<DivisionSelectorDTO>> {
    return this.apiService.get(this.API_NAME, 'getIntroducerDivisions', {
      params: {
        introducerId: id
      }
    }).toPromise<ListResult<DivisionSelectorDTO>>();
  }

  public addSalesAdvisor(id, body) {
    return this.apiService.post('salesAdvisor', 'addSalesAdvisor', body, {
      params: {
        introducerId: id
      }
    }).toPromise();
  }

  public viewSalesAdvisor(introducerId: string, salesAdvisorId: string): Promise<SalesAdvisorFormDTO> {
    const params = { introducerId, salesAdvisorId };

    return this.apiService.get('salesAdvisor', 'viewSalesAdvisor', { params }).toPromise();
  }

  public editSalesAdvisor(introducerId, salesAdvisorId, body) {
    return this.apiService.post('salesAdvisor', 'editSalesAdvisor', body, {
      params: {
        introducerId: introducerId,
        salesAdvisorId: salesAdvisorId
      }
    }).toPromise();
  }

  public removeSalesAdvisor(id) {
    return this.apiService.delete('salesAdvisor', 'removeSalesAdvisor', {
      params: {
        salesAdvisorId: id
      }
    }).toPromise();
  }

  public listDivisions(params: any) {
    return this.apiService.get(this.API_NAME, 'listDivisions', { params });
  }

  public viewDivision(introducerId: string, divisionId: string): Promise<any> {
    return this.apiService.get(this.API_NAME, 'viewDivision', { params: { introducerId, divisionId } }).toPromise();
  }

  public addDivision(introducerId: string, data: any): Promise<any> {
    return this.apiService.put(this.API_NAME, 'addDivision', data, { params: { introducerId } }).toPromise();
  }

  public editDivision(introducerId: string, divisionId: string, data: any): Promise<any> {
    return this.apiService.post(this.API_NAME, 'editDivision', { ...data, introducerId, divisionId }).toPromise();
  }

  public removeDivision(introducerId: string, divisionId: string): Promise<any> {
    return this.apiService.delete(this.API_NAME, 'removeDivision', { params: { introducerId, divisionId } }).toPromise();
  }

  public async getIntroducerRegions(introducerId: string): Promise<RegionSelectorDTO[]> {
    const params = { introducerId };
    const result: ListResult<RegionSelectorDTO> = await this.apiService.get(this.API_NAME, 'getIntroducerRegions', { params }).toPromise();

    return result.items;
  }

  public listRegions(params: any) {
    return this.apiService.get(this.API_NAME, 'listRegions', { params });
  }

  public viewRegion(introducerId: string, regionId: string): Promise<Region> {
    const params = { introducerId, regionId };

    return this.apiService
      .get(this.API_NAME, 'viewRegion', { params })
      .toPromise();
  }

  public addRegion(introducerId: string, region: Region): Promise<Region> {
    const params = { introducerId };

    return this.apiService
      .put(this.API_NAME, 'addRegion', region, { params })
      .toPromise();
  }

  public editRegion(introducerId: string, regionId: string, region: Region): Promise<void> {
    const params = { introducerId, regionId };

    return this.apiService
      .post(this.API_NAME, 'editRegion', region, { params })
      .toPromise();
  }

  public removeRegion(introducerId: string, regionId: string): Promise<void> {
    const params = { introducerId, regionId };

    return this.apiService
      .delete(this.API_NAME, 'removeRegion', { params })
      .toPromise();
  }

  public getIntroducerLinks(introducerId: string): Promise<IntroducerLinks> {
    const params = { introducerId };

    return this.apiService
      .get(this.API_NAME, 'getIntroducerLinks', { params })
      .toPromise();
  }

  private removeLogoWithoutFilename(body) {
    !body.logo.filename && delete body.logo;
  }
}
