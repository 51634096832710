import { Injectable } from "@angular/core";

import { ApiService } from "../../root/services/api.service";
import { Attachment } from "../../../../../../src/app/website/models/attachment";
import { APPLICATION_STEP, APPLICATION_STEP_STATUS, APPLICANT_RELATIONSHIP, DEPENDANT_TYPE, DEPENDANT_GENDER, RESIDENTIAL_STATUS, EMPLOYMENT_STATUS, INCOME_TYPE, OUTGOINGS_CATEGORY, CREDIT_TYPE, LOAN_NAME, REPAY_METHOD, PROPERTY_PAYMENT_METHOD, PROPERTY_ASSET_STATUS, PROPERTY_TYPE, PROPERTY_FLAT_TYPE, PROPERTY_HOUSE_TYPE, PROPERTY_TENURE, PROPERTY_BEDROOMS, ASSET_TYPE, PRODUCT_TYPE, APPLICANT_CATEGORY, DEBIT_COLLECTION_DATE, DOCUMENT_TYPE, PERSONAL_TITLE, GENDER, NAME_CHANGE_REASON, MARTITAL_STATUS, VISA_STATUS, APPLICANT_SCHEME, APPLICATION_TYPE, DEPOSIT_SOURCE_TYPE, APPLICATION_STEP_NAME, SMART_SEARCH_AML_RESULT, REGION_TYPE } from "../enums.module";
import { formatDate } from "@angular/common";
import { Environment } from "../../../../../../src/app/website/services/environment.service";
import { HttpClient } from "@angular/common/http";
import { HfValidators } from '../../../../../../src/app/website/utils/form.validators';
import { AdvisorDTO } from "../../root/models/advisor.dto";
import { isIOS } from "../../shared/functions/is-ios";
import { USER_TYPE } from "../../../../../../src/app/website/utils/variables.data";
import { SignatureDTO } from "../../shared/model/signature.dto";

interface ListResult<T> {
  items: T[];
}

export interface ApplicantUser {
  id: string;

  firstName: string;
  lastName: string;
  fullName: string;

  email: string;

  isLead: boolean;
}

export interface TermsAndConditions {
  id: string;
  createdDate: string;
  text: string;
}

export interface FactFindStepStatus {
  step: APPLICATION_STEP;
  status: APPLICATION_STEP_STATUS;
  markToReturn: boolean;
  lastUpdated: string;
  updatedBy?: UserSimple;
  prefilled: boolean;
  returnComment?: string;
}

export interface FactFindStatus {
  stepStatuses: FactFindStepStatus[];
  readyToSign: boolean;
  signedInLead: boolean;
  applicantCategory: APPLICANT_CATEGORY;
  remainingMortgageAmount: number;
  waitingForCompletion?: string[];
  advisor?: AdvisorDTO;
}

export interface Integrations {
  useMogo: boolean;
  useSmartSearch: boolean;
}

export interface IntegrationsResponse extends Integrations {
  mogoForwardingUrl: string;
}

export interface Applicant {
  client: {
    id: string,
    firstName: string,
    lastName: string,
    email: string;
    phone: string,
    phonePrefix: string,
  },
  lead: boolean;
  relationship: APPLICANT_RELATIONSHIP,
  otherRelationship: string,
  sharingAddress: boolean

  textSignature?: string;
  signature?: Attachment;
}

export interface ApplicationDetails {
  id: string;
  firstTimeBuyer: boolean
  applicantCategory: APPLICANT_CATEGORY
  applicantScheme: APPLICANT_SCHEME
  applicationType: APPLICATION_TYPE

  helpToBuyLoan: number
  rightToBuyDiscount: number
  sharedPercentage: number
  sharedMonthlyRent: number
}

export interface Application {
  applicationDetails: ApplicationDetails;
  applicants: Applicant[];
}

export interface Nationality {
  id: number;
  name: string;
  european: boolean;
}

export interface Dependant {
  id: string;

  type: DEPENDANT_TYPE;
  otherType?: string;
  fullName: string;
  birthDate: Date;
  gender: DEPENDANT_GENDER;
  otherGender?: string;

  addedByData: { id: string, fullName: string, firstName: string, lastName: string };
}

export interface PersonalDetails {
  title: PERSONAL_TITLE;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: GENDER
  otherGender: string;
  changedName: boolean;
  previousSurname: string;
  nameChangeReason: NAME_CHANGE_REASON;
  nameChangeOtherReason: string;
  nameChangeDate: Date
  maritalStatus: MARTITAL_STATUS;
  marriageDate: Date;
  birthDate: Date;
  phonePrefix: string;
  phone: string;
  whatsAppUpdates: boolean;
  email: string;
  nationality: { id: string };
  visaStatus: VISA_STATUS;
}

export interface Address {
  postcode: string;
  house: string;
  street: string;
  line2: string;
  city: string;
  country: string;
  region?: REGION_TYPE;
  _isUk?: boolean;
}
export interface AddressHistory {
  id: string;
  address: Address;

  residentialStatus: RESIDENTIAL_STATUS;
  otherResidentialStatus?: string;
  landlordName: string;
  residentSince: Date;
}

export interface EmploymentHistory {
  id: string;
  employmentStatus: EMPLOYMENT_STATUS;

  employerName: string;
  employerAddress: Address;
  occupation: string;

  limitedCompany: boolean;
  sharePercentage: number;
  annualDividends: number;
  yearsTrading: number;
  yearsAccounts: number;
  accountantName: string;
  accountantEmail: string;
  accountantPhonePrefix: string;
  accountantPhone: string;

  salary: number;
  guaranteedOvertime: number;
  notGuaranteedOvertime: number;
  regularBonus: number;
  regularCommission: number;
  carAllowance: number;
  largeTownAllowance: number;
  otherIncome: boolean;
  otherIncomeAmount: number;

  otherDescription: string;
  startDate: Date;
  endDate: Date;

  currentYearProjectedSalary: number;
  currentYearProjectedDividends: number;
  currentYearProjectedNetProfit: number;
  lastYearAnnualSalary: number;
  lastYearAnnualDividends: number;
  lastYearNetProfit: number;
  lastButOneYearAnnualSalary: number;
  lastButOneYearDividends: number;
  lastButOneYearNetProfit: number;
  lastButTwoYearsAnnualSalary: number;
  lastButTwoYearsDividends: number;
  lastButTwoYearsNetProfit: number;
}

export interface OtherIncome {
  id: string;
  incomeType: INCOME_TYPE;
  otherIncomeType: string;
  amount: number;
  incomeStartDate: Date;
}

export interface EmploymentDetails {
  employments: EmploymentHistory[];
  incomes: OtherIncome[];

  nationalInsuranceNumber: string;
  retirementAge: number;
}

export interface UserSimple {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  userType: USER_TYPE;
}

export interface Expense {
  category: OUTGOINGS_CATEGORY;
  amount: number;
  mogo: boolean;
  experian: boolean;
  updated: Date;
  updatedBy: UserSimple;
}

export interface ApplicantOutgoings {
  applicant: UserSimple;
  outgoings: Expense[];
}

export interface MonthlyOutgoings {
  applicants: ApplicantOutgoings[];
}

export interface CreditLiabilityDetails {
  bankrupt:	boolean;
  bankruptDetails:	string;
  previousArrears:	boolean;
  previousArrearsDetails:	string;
  overdraft:	boolean;
  overdraftLimit:	number;
  overdraftUsed:	number;
  overdraftWillFullyRepay:	boolean;
  latePayments: boolean;
  ccjDefaults: boolean;
}

export interface CreditLiability {
  id: string;
  type: CREDIT_TYPE;
  onName: LOAN_NAME;
  otherPersonName: string;
  lender: string;
  currentBalance: number;
  approximateEndDate: Date;
  monthlyPayment: number;
  repayMethod: REPAY_METHOD;
  otherRepayMethod: string;
  willFullyRepay: boolean;
}

export interface CreditLiabilityData {
  creditLiabilities: CreditLiability[];
  details: CreditLiability;
}

export interface AssetPropertyDetails {
  mortgageOnProperty: boolean;
  lender: string;
  loanAmount: number;
  monthlyPayment: number;
  remainingMortgage: {
    years: number;
    months: number;
  };
  productEndDateKnown: boolean;
  productEndDate: Date;
  earlyRedemptionCharge: boolean;
  earlyRedemptionChargeAmount: number;
  paymentMethod: PROPERTY_PAYMENT_METHOD;
  rentingProperty: boolean;
  rentAmount: number;
  status: PROPERTY_ASSET_STATUS;
  otherStatus: string;
  propertyType: PROPERTY_TYPE;
  flatType: PROPERTY_FLAT_TYPE;
  exLocal: boolean;
  storiesNum: number;
  annualGroundRent: number;
  monthlyServiceCharge: number;
  houseType: PROPERTY_HOUSE_TYPE;
  propertyTenure: PROPERTY_TENURE;
  remainingOnLease: {
    years: number;
    months: number;
  };
  bedrooms: PROPERTY_BEDROOMS;
  propertyValue: number;
  purchaseDate: Date;
  address: Address;
  willBeSelling: boolean;
  willBeUsingForPurchase: boolean;
  remortgagingProperty: boolean;
}

export interface Asset {
  id: string;
  type: ASSET_TYPE;
  assetValue: number;
  otherTypeName: string;
  propertyDetails: AssetPropertyDetails;
  clientData: { id: string, fullName: string, firstName: string, lastName: string };
}

export interface Solicitor {
  name: string;
  address: Address;
  phonePrefix: string;
  phone: string;
  email: string;
}

export interface DepositSource {
  type: DEPOSIT_SOURCE_TYPE;
  otherType?: string;
  amount: number;
}

export type PropertyRemortgageIntent = 
  | "LIKE_FOR_LIKE_REMORTGAGE"
  | "INCREASE_MORTGAGE"
  | "REDUCE_MORTGAGE"

export const PropertyRemortgageIntentName = new Map<PropertyRemortgageIntent, string>([
  ["LIKE_FOR_LIKE_REMORTGAGE", "Like-for-like remortgage"],
  ["INCREASE_MORTGAGE", "Increase mortgage"],
  ["REDUCE_MORTGAGE", "Reduce mortgage"],
])

export interface PropertyDetails {
  id: number;
  propertyUnknown: boolean;
  address: Address;
  purchasePrice: number;
  currentValuation: number;
  toBorrow: number;

  anticipatedRentalIncome: number;
  propertyRemortgageIntent: PropertyRemortgageIntent;
  reasonForIncreasingBorrowing: string;
  sourceOfReducedBorrowingFunds: string;

  overTerm: {
    years: number;
    months: number;
  };
  depositSources: DepositSource[];
  productType: PRODUCT_TYPE;
  paymentMethod: PROPERTY_PAYMENT_METHOD
  interestOnlyOptions: {
    savingsInvestment: boolean;
    propertySale: boolean;
    convertToLaterRepayment: boolean;
    anticipatedInheritance: boolean;
    irregularRepayments: boolean;
  };
  propertyType: PROPERTY_TYPE;
  flatType: PROPERTY_FLAT_TYPE;
  exLocal: boolean;
  storiesNum: number;
  annualGroundRent: number;
  monthlyServiceCharge: number;
  houseType: PROPERTY_HOUSE_TYPE;
  propertyTenure: PROPERTY_TENURE;
  remainingOnLease: {
    years: number;
    months: number;
  };
  bedrooms: PROPERTY_BEDROOMS;
  propertyBuild: Date;
  noClaimsDiscounts: number;
  buyingThoughEstateAgent: boolean;
  estateAgentName: string;
  estateAgentContactName: string;
  estateAgentAddress: Address;
  estateAgentPhonePrefix: string;
  estateAgentPhone: string;
  estateAgentEmail: string;
  incentivesBeingOffered: boolean;

  ownSolicitor: boolean;
  solicitor: Solicitor;

  _knowProperty: boolean;
}

export interface PaymentDetails {
  accountHolder: string;
  bank: string;
  branch: string;
  accountNumber: string;
  sortCode: string;
  directDebitCollection: DEBIT_COLLECTION_DATE;
  directDebitCollectionDay: number;
}

export interface Document {
  type: DOCUMENT_TYPE;
  file?: Attachment;
}

export interface Documents {
  requiredTypesPersonal: DOCUMENT_TYPE[];
  requiredTypesCollaborative: DOCUMENT_TYPE[];
  optionalTypesCollaborative: DOCUMENT_TYPE[];

  documents: Document[];
}

export interface SmartSearchResult {
  useSmartsearch: boolean;
  createdDate: Date;
  amlResult: SMART_SEARCH_AML_RESULT;
  errors: string;
  link: string;
}

export interface ApplicantData {
  applicant: { client: { id: string, fullName: string }, lead?: boolean };
  addressHistory: AddressHistory[];
  personalDetails: PersonalDetails;
  employments: EmploymentDetails[];
  incomes: OtherIncome[]
  creditLiabilities: CreditLiability[];
  creditLiabilityDetails: CreditLiabilityDetails;
  outgoings: Expense[];
  personalDocuments: Document[];

}

export interface FactFindData {
  applicants: Applicant[];
  applicationDetails: ApplicationDetails;
  dependants: Dependant[];
  assets: Asset[];
  applicantsData: ApplicantData[];
  collaborativeDocuments: Document[];
  propertyDetails: PropertyDetails;
  paymentDetails: PaymentDetails;
}

export function parseIsoDate(date: any): Date {
  if (!date) {
    return undefined;
  }

  const parts = date.split("-").map(Number);

  return new Date(parts[0], parts[1] - 1, parts[2]);
}

export function dateToIso(date: Date): string {
  if (!date) {
    return undefined;
  }

  return formatDate(date, "yyyy-MM-dd", "en-GB");
}

@Injectable({ providedIn: "root" })
export class FactFindApiService {
  private readonly API_NAME = "factFind";

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private environment: Environment
  ) { }

  public async getCurrentTermsAndConditions(): Promise<TermsAndConditions> {
    return this.apiService
      .get(this.API_NAME, "getCurrentTermsAndConditions")
      .toPromise();
  }

  public async acceptTermsAndConditions(mortgageId: string, applicantId: string, termsAndConditionsId): Promise<any> {
    return this.apiService
      .post(this.API_NAME, "acceptTermsAndConditions", { mortgageId, applicantId, termsAndConditionsId })
      .toPromise();
  }

  public async getFactFindStatus(mortgageId: string, applicantId?: string): Promise<FactFindStatus> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    const result = await this.apiService
      .get(this.API_NAME, "getFactFindStatus", { params })
      // .pipe(tap((r) => console.log("getFactFindStatus result: ", r)))
      .toPromise<FactFindStatus>();

    // sort
    const stepsSorted = Array.from(APPLICATION_STEP_NAME.keys());
    result.stepStatuses = result.stepStatuses.sort((a, b) => stepsSorted.indexOf(a.step) - stepsSorted.indexOf(b.step));

    return result;
  }

  public async getApplicants(mortgageId: string): Promise<ApplicantUser[]> {
    const result = await this.apiService
      .get(this.API_NAME, "getApplicants", { params: { mortgageId } })
      .toPromise<ListResult<ApplicantUser>>();

    return result.items.map((applicant: any) => {
      applicant.isLead = applicant.lead;
      delete applicant.lead

      return applicant;
    });
  }

  public async saveIntegrations(mortgageId: string, applicantId: string, integrations: Integrations): Promise<IntegrationsResponse> {
    return this.apiService
      .post(this.API_NAME, "saveIntegrations", integrations, { params: { mortgageId, applicantId } })
      .toPromise();
  }

  public async getApplicationDetails(mortgageId: string): Promise<Application> {
    return this.apiService
      .get(this.API_NAME, "getApplicationDetails", { params: { mortgageId } })
      .toPromise();
  }

  public async saveApplicationDetails(mortgageId: string, application: Application): Promise<any> {
    return this.apiService
      .post(this.API_NAME, "saveApplicationDetails", application, { params: { mortgageId } })
      .toPromise();
  }

  public async addApplicant(mortgageId: string, applicant: Applicant): Promise<Applicant> {
    return this.apiService
      .post(this.API_NAME, "addApplicant", applicant, { params: { mortgageId } })
      .toPromise();
  }

  public async editApplicant(mortgageId: string, applicantId: string, applicant: Applicant): Promise<Applicant> {
    return this.apiService
      .post(this.API_NAME, "editApplicant", applicant, { params: { mortgageId, applicantId } })
      .toPromise();
  }

  public async deleteApplicant(mortgageId: string, applicantId: string): Promise<any> {
    return this.apiService
      .delete(this.API_NAME, "deleteApplicant", { params: { mortgageId, applicantId } })
      .toPromise();
  }

  public async changeLeadApplicant(mortgageId: string, newLeadApplicantId: string): Promise<any> {
    return this.apiService
      .post(this.API_NAME, "changeLeadApplicant", undefined, { params: { mortgageId, newLeadApplicantId } })
      .toPromise();
  }

  public async getPersonalDetails(mortgageId: string, applicantId?: string): Promise<PersonalDetails> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    const details = await this.apiService
      .get(this.API_NAME, "getPersonalDetails", { params: params })
      .toPromise<PersonalDetails>();

    details.marriageDate && (details.marriageDate = parseIsoDate(details.marriageDate));
    details.nameChangeDate && (details.nameChangeDate = parseIsoDate(details.nameChangeDate));
    details.birthDate && (details.birthDate = parseIsoDate(details.birthDate));

    return details;
  }

  public async getNationalities(): Promise<Nationality[]> {
    const result = await this.apiService
      .get(this.API_NAME, "getNationalities")
      .toPromise<ListResult<Nationality>>();

    const britishId = 28;
    const britishIdIndex = result.items.findIndex(nationality => nationality.id === britishId);
    const british = result.items.splice(britishIdIndex, 1)
    const sorted = [...british, ...result.items];

    if (isIOS) {
      const dummy: Nationality = {
        id: 0,
        name: "",
        european: true
      };

      return [dummy, ...sorted];
    }

    return sorted;
  }

  public async savePersonalDetails(personalDetails: PersonalDetails, mortgageId: string, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };
    const details = {...personalDetails};

    details.marriageDate && (details.marriageDate = dateToIso(details.marriageDate) as any);
    details.nameChangeDate && (details.nameChangeDate = dateToIso(details.nameChangeDate) as any);
    details.birthDate && (details.birthDate = dateToIso(details.birthDate) as any);

    applicantId || delete params.applicantId;

    return this.apiService
      .post(this.API_NAME, "savePersonalDetails", details, { params: params })
      .toPromise();
  }

  // Dependants

  private parseIsoDatesInDependants(result: ListResult<Dependant>) {
    result.items.forEach(d => d.birthDate = parseIsoDate(d.birthDate as any));
  }

  public async getDependants(mortgageId: string): Promise<Dependant[]> {
    const result = await this.apiService
      .get(this.API_NAME, "getDependants", { params: { mortgageId } })
      .toPromise<ListResult<Dependant>>();

    this.parseIsoDatesInDependants(result);

    return result.items;
  }

  public async addDependant(mortgageId: string, dependant: Dependant, applicantId?: string): Promise<Dependant[]> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;
    dependant.birthDate = dateToIso(dependant.birthDate) as any;

    const result = await this.apiService
      .post(this.API_NAME, "addDependant", dependant, { params: params })
      .toPromise();

    this.parseIsoDatesInDependants(result);

    return result.items;
  }

  public async editDependant(mortgageId: string, dependantId: string, dependant: Dependant): Promise<Dependant[]> {
    dependant.birthDate = dateToIso(dependant.birthDate) as any;

    const result = await this.apiService
      .post(this.API_NAME, "editDependant", dependant, { params: { mortgageId, dependantId } })
      .toPromise();

    this.parseIsoDatesInDependants(result);

    return result.items;
  }

  public async deleteDependant(mortgageId: string, dependantId: string): Promise<Dependant[]> {
    const result = await this.apiService
      .delete(this.API_NAME, "deleteDependant", { params: { mortgageId, dependantId } })
      .toPromise();

    this.parseIsoDatesInDependants(result);

    return result.items;
  }

  public async confirmDependants(mortgageId: string, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    return this.apiService
      .post(this.API_NAME, "confirmDependants", undefined, { params })
      .toPromise();
  }

  // Address History

  private parseIsoDatesInAddressHistory(result: ListResult<AddressHistory>) {
    result.items.forEach(d => d.residentSince = parseIsoDate(d.residentSince as any));
  }

  public async getAddressHistory(mortgageId: string, applicantId?: string): Promise<AddressHistory[]> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    const result = await this.apiService
      .get(this.API_NAME, "getAddressHistory", { params })
      .toPromise<ListResult<AddressHistory>>();

    this.parseIsoDatesInAddressHistory(result);

    return result.items;
  }

  public async addAddressHistory(mortgageId: string, addressHistory: AddressHistory, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;
    addressHistory.residentSince = dateToIso(addressHistory.residentSince) as any;

    const result = await this.apiService
      .post(this.API_NAME, "addAddressHistory", addressHistory, { params: params })
      .toPromise();

    return result;
  }

  public async editAddressHistory(mortgageId: string, addressHistoryId: string, addressHistory: AddressHistory, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId, addressHistoryId };

    applicantId || delete params.applicantId;
    addressHistory.residentSince = dateToIso(addressHistory.residentSince) as any;

    const result = await this.apiService
      .post(this.API_NAME, "editAddressHistory", addressHistory, { params })
      .toPromise();

    return result;
  }

  public async deleteAddressHistory(mortgageId: string, addressHistoryId: string, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId, addressHistoryId };

    applicantId || delete params.applicantId;

    return this.apiService
      .delete(this.API_NAME, "deleteAddressHistory", { params })
      .toPromise();
  }

  public async confirmAddressHistory(mortgageId: string, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    return this.apiService
      .post(this.API_NAME, "confirmAddressHistory", undefined, { params })
      .toPromise();
  }

  // Employment Income

  public async getEmploymentAndIncomeData(mortgageId: string, applicantId?: string): Promise<EmploymentDetails> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    const result = await this.apiService
      .get(this.API_NAME, "getEmploymentAndIncomeData", { params } )
      .toPromise<EmploymentDetails>();

    result.employments = result.employments.map(e => ({
      ...e,
      startDate: parseIsoDate(e.startDate as any),
      endDate: parseIsoDate(e.endDate as any)
    }));

    result.incomes = result.incomes.map(i => ({
      ...i,
      incomeStartDate: parseIsoDate(i.incomeStartDate as any)
    }));

    return result;
  }

  public async addEmploymentHistory(mortgageId: string, employmentHistory: EmploymentHistory, applicantId?: string): Promise<EmploymentHistory> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;
    employmentHistory.startDate = dateToIso(employmentHistory.startDate) as any;
    employmentHistory.endDate = dateToIso(employmentHistory.endDate) as any;

    const result = await this.apiService
      .post(this.API_NAME, "addEmploymentHistory", employmentHistory, { params: params })
      .toPromise();

    return result;
  }

  public async editEmploymentHistory(mortgageId: string, employmentHistoryId: string, employmentHistory: EmploymentHistory, applicantId?: string): Promise<EmploymentHistory> {
    const params = { mortgageId, applicantId, employmentHistoryId };

    applicantId || delete params.applicantId;
    employmentHistory.startDate = dateToIso(employmentHistory.startDate) as any;
    employmentHistory.endDate = dateToIso(employmentHistory.endDate) as any;

    const result = await this.apiService
      .post(this.API_NAME, "editEmploymentHistory", employmentHistory, { params })
      .toPromise();

    return result;
  }

  public async deleteEmploymentHistory(mortgageId: string, employmentHistoryId: string, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId, employmentHistoryId };

    applicantId || delete params.applicantId;

    return this.apiService
      .delete(this.API_NAME, "deleteEmploymentHistory", { params })
      .toPromise();
  }

  public async addOtherIncome(mortgageId: string, otherIncome: OtherIncome, applicantId?: string): Promise<OtherIncome> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;
    otherIncome.incomeStartDate = dateToIso(otherIncome.incomeStartDate) as any;

    return this.apiService
      .post(this.API_NAME, "addOtherIncome", otherIncome, { params })
      .toPromise();
  }

  public async editOtherIncome(mortgageId: string, otherIncomeId: string, otherIncome: OtherIncome, applicantId?: string): Promise<OtherIncome> {
    const params = { mortgageId, otherIncomeId, applicantId };

    applicantId || delete params.applicantId;
    otherIncome.incomeStartDate = dateToIso(otherIncome.incomeStartDate) as any;

    return this.apiService
      .post(this.API_NAME, "editOtherIncome", otherIncome, { params })
      .toPromise();
  }

  public async deleteOtherIncome(mortgageId: string, otherIncomeId: string, applicantId?: string): Promise<any> {
    const params = { mortgageId, otherIncomeId, applicantId };

    applicantId || delete params.applicantId;

    return this.apiService
      .delete(this.API_NAME, "deleteOtherIncome", { params })
      .toPromise();
  }

  public async saveEmploymentDetails(mortgageId: string, employmentDetails: EmploymentDetails, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    return this.apiService
      .post(this.API_NAME, "saveEmploymentDetails", employmentDetails, { params })
      .toPromise();
  }

  // Monthly Outgoings

  public async getMonthlyOutgoings(mortgageId: string, applicantId?: string): Promise<MonthlyOutgoings> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    const result = await this.apiService
      .get(this.API_NAME, "getMonthlyOutgoings", { params } )
      .toPromise<MonthlyOutgoings>();

    return result;
  }

  public async saveMonthlyOutgoings(mortgageId: string, monthlyOutgoings: MonthlyOutgoings, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    const result = await this.apiService
      .post(this.API_NAME, "saveMonthlyOutgoings", monthlyOutgoings, { params } )
      .toPromise();

    return result;
  }

  // Credit Liabilities

  public async getCreditLiabilitiesDetails(mortgageId: string, applicantId?: string): Promise<CreditLiabilityData> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    const result = await this.apiService
      .get(this.API_NAME, "getCreditLiabilitiesDetails", { params } )
      .toPromise<CreditLiabilityData>();

    result.creditLiabilities = result.creditLiabilities.map(cl => {
      cl.approximateEndDate = parseIsoDate(cl.approximateEndDate as any);

      return cl;
    })

    return result;
  }

  public async addCreditLiability(mortgageId: string, creditLiability: CreditLiability, applicantId?: string): Promise<CreditLiability> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;
    creditLiability.approximateEndDate = dateToIso(creditLiability.approximateEndDate) as any;

    const result = await this.apiService
      .post(this.API_NAME, "addCreditLiability", creditLiability, { params })
      .toPromise<CreditLiability>();

    result.approximateEndDate = parseIsoDate(result.approximateEndDate as any);

    return result;
  }

  public async editCreditLiability(mortgageId: string, creditLiabilityId: string, creditLiability: CreditLiability, applicantId?: string): Promise<CreditLiability> {
    const params = { mortgageId, creditLiabilityId, applicantId };

    applicantId || delete params.applicantId;
    creditLiability.approximateEndDate = dateToIso(creditLiability.approximateEndDate) as any;

    const result = await this.apiService
      .post(this.API_NAME, "editCreditLiability", creditLiability, { params })
      .toPromise<CreditLiability>();

    result.approximateEndDate = parseIsoDate(result.approximateEndDate as any);

    return result;
  }

  public async deleteCreditLiability(mortgageId: string, creditLiabilityId: string, applicantId?: string): Promise<any> {
    const params = { mortgageId, creditLiabilityId, applicantId };

    applicantId || delete params.applicantId;

    return this.apiService
      .delete(this.API_NAME, "deleteCreditLiability", { params })
      .toPromise();
  }

  public async saveCreditLiabilities(mortgageId: string, creditLiabilityDetails: CreditLiabilityDetails, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    return this.apiService
      .post(this.API_NAME, "saveCreditLiabilities", creditLiabilityDetails, { params })
      .toPromise();
  }

  // Assets

  public async getAssets(mortgageId: string): Promise<Asset[]> {
    const result = await this.apiService
      .get(this.API_NAME, "getAssets", { params: { mortgageId } } )
      .toPromise<ListResult<Asset>>();

    result.items?.forEach(asset => {
      if (asset.propertyDetails?.productEndDate) {
        asset.propertyDetails.productEndDate = parseIsoDate(asset.propertyDetails.productEndDate);
      }
      if (asset.propertyDetails?.purchaseDate) {
        asset.propertyDetails.purchaseDate = parseIsoDate(asset.propertyDetails.purchaseDate);
      }
    });

    return result.items;
  }

  public async addAsset(mortgageId: string, asset: Asset, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    if (asset.propertyDetails?.productEndDate) {
      asset.propertyDetails.productEndDate = dateToIso(asset.propertyDetails.productEndDate) as any;
    }
    if (asset.propertyDetails?.purchaseDate) {
      asset.propertyDetails.purchaseDate = dateToIso(asset.propertyDetails.purchaseDate) as any;
    }

    const result = await this.apiService
      .post(this.API_NAME, "addAsset", asset, { params })
      .toPromise();

    return result;
  }

  public async editAsset(mortgageId: string, assetId: string, asset: Asset, applicantId?: string): Promise<any> {
    const params = { mortgageId, assetId, applicantId };

    applicantId || delete params.applicantId;
    
    if (asset.propertyDetails?.productEndDate) {
      asset.propertyDetails.productEndDate = dateToIso(asset.propertyDetails.productEndDate) as any;
    }
    if (asset.propertyDetails?.purchaseDate) {
      asset.propertyDetails.purchaseDate = dateToIso(asset.propertyDetails.purchaseDate) as any;
    }

    const result = await this.apiService
      .post(this.API_NAME, "editAsset", asset, { params })
      .toPromise();

    return result;
  }

  public async deleteAsset(mortgageId: string, assetId: string): Promise<any> {
    const params = { mortgageId, assetId };

    const result = await this.apiService
      .delete(this.API_NAME, "deleteAsset", { params })
      .toPromise();

    return result;
  }

  public async confirmAssets(mortgageId: string, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    return this.apiService
      .post(this.API_NAME, "confirmAssets", undefined, { params })
      .toPromise();
  }

  // Property Details

  public async getPropertyDetails(mortgageId: string, applicantId?: string): Promise<PropertyDetails> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    const result = await this.apiService
      .get(this.API_NAME, "getPropertyDetails", { params } )
      .toPromise<PropertyDetails>();

    return result;
  }

  public async savePropertyDetails(mortgageId: string, propertyDetails: PropertyDetails, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    return this.apiService
      .post(this.API_NAME, "savePropertyDetails", propertyDetails, { params } )
      .toPromise();
  }

  // Payment Details

  public async getPaymentDetails(mortgageId: string, applicantId?: string): Promise<PaymentDetails> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    const result = await this.apiService
      .get(this.API_NAME, "getPaymentDetails", { params } )
      .toPromise<PaymentDetails>();

    return result;
  }

  public async savePaymentDetails(mortgageId: string, paymentDetails: PaymentDetails, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    return this.apiService
      .post(this.API_NAME, "savePaymentDetails", paymentDetails, { params } )
      .toPromise();
  }

  // Documents

  public async addDocument(mortgageId: string, type: DOCUMENT_TYPE, file: File, applicantId?: string): Promise<Document> {
    HfValidators.validateMaxFileSizeDisk(file);
    const url = `${this.environment.apiUrl}/servlet/addFactFindDocument`;
    const params = { mortgageId, applicantId, type, filename: file.name };

    applicantId || delete params.applicantId;

    return this.httpClient.post<Document>(url, file, {
      params,
      headers: { "Content-Type": file.type },
      withCredentials: true
    }).toPromise();
  }

  public async getDocuments(mortgageId: string, applicantId: string): Promise<Documents> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    const result = await this.apiService
      .get(this.API_NAME, "getDocuments", { params } )
      .toPromise<Documents>();

    return result;
  }

  public async deleteDocument(mortgageId: string, fileId: string, applicantId?: string): Promise<any> {
    const params = { mortgageId, fileId, applicantId };

    applicantId || delete params.applicantId;

    return this.apiService
      .delete(this.API_NAME, "deleteDocument", { params })
      .toPromise();
  }

  public async confirmDocuments(mortgageId: string, applicantId?: string): Promise<any> {
    const params = { mortgageId, applicantId };

    applicantId || delete params.applicantId;

    return this.apiService
      .post(this.API_NAME, "confirmDocuments", undefined, { params })
      .toPromise();
  }

  public async getFactFindData(mortgageId: string): Promise<FactFindData> {
    const result = await this.apiService
      .get(this.API_NAME, "getFactFindData", { params: { mortgageId } } )
      .toPromise<any>();

    result.applicantsData = result.applicantsData.sort((a, b) =>
      a.applicant.client.id < b.applicant.client.id
        ? -1
        : 1
    )

    return result;
  }

  public async signFactFind(mortgageId: string, signature: SignatureDTO): Promise<any> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "signFactFind", signature, { params })
      .toPromise();
  }

  public async signFactFindHeron(mortgageId: string): Promise<any> {
    const params = { mortgageId };

    return this.apiService
      .post(this.API_NAME, "signFactFindHeron", undefined, { params })
      .toPromise();
  }
}
