// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serviceWorkerEnabled: true,

  apiUrl: "https://everglades-dev-api.projects.sprint-in.com/",
  apiVersion: "/v1/",
  rawApiUrl: "https://everglades-dev-api.projects.sprint-in.com/_ah/api/",

  // apiUrl: 'https://everglades-uat-api.projects.sprint-in.com/',
  // apiVersion: '/v1/',
  // rawApiUrl: 'https://everglades-uat-api.projects.sprint-in.com/_ah/api/',

  config: {
    apiKey: "AIzaSyD9Xy6JpPOveZUTxVhA0PehSWMxUNfkai0",
    authDomain: "everglades-development.firebaseapp.com",
    databaseURL: "https://everglades-development.firebaseio.com",
    projectId: "everglades-development",
    storageBucket: "everglades-development.appspot.com",
    messagingSenderId: "623026858390",
  },
  sentryDsn:
    "https://451f75ee83bb4469b7fa0817e38af9c6@o4504338104778752.ingest.sentry.io/4505359075246080",
  requestFactFindEnabled: true,
  clientWebUrl: "https://everglades-client-dev.projects.sprint-in.com",
  swaggerApiLinks: {
    introducerApi:
      "https://everglades-dev-api.projects.sprint-in.com/servlet/swagger-ui/index.html",
  },
  twenty7tecSourceUrl:
    "https://stagingcloud.twenty7tec.com/Sourcing/SourceDetails",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
