<section class="section">
  <div class="boxes-area">
    <hf-information-box
      heading="Next Action"
      [value]="{
        text:
          data?.nextAction &&
          convertEnumToValue(MORTGAGE_ACTIONS, data?.nextAction)
      }"
    >
    </hf-information-box>

    <hf-information-box
      heading="Waiting On"
      [value]="{
        text:
          data?.waitingOn &&
          convertEnumToValue(MORTGAGE_WAITING_ON, data?.waitingOn)
      }"
    >
    </hf-information-box>

    <hf-information-box
      heading="Date Created"
      [value]="{ text: data?.createdDate | date : 'd MMM y' }"
    >
    </hf-information-box>
  </div>

  <div class="boxes-area">
    <hf-information-box
      heading="Appointment"
      [actionVisible]="isAppointmentVisible"
      [action]="appointmentAction"
      [value]="appointmentValue"
    >
    </hf-information-box>

    <hf-information-box
      *ngIf="isHelpBuyVisible"
      heading="Help to Buy"
      [actionVisible]="isAppointmentVisible"
      [action]="helpBuyAction"
      [value]="helpBuyValue"
      [lastUpdate]="data?.helpToBuy?.lastAction"
      [lastUpdateVisible]="!!data?.helpToBuy"
    >
    </hf-information-box>

    <hf-information-box
      heading="Fact Find"
      [actionVisible]="isFactFindVisible"
      [action]="factFindAction"
      [value]="factFindValue"
      [lastUpdate]="data?.factFind?.lastAction"
      [lastUpdateVisible]="!!data?.factFind"
    >
    </hf-information-box>

    <hf-information-box
      *ngIf="data.targetExchangedDate && data.state !== 'COMPLETED'"
      heading="Target Completion Date"
    >
      <ng-template #valueTemplate>
        <div style="display: flex; align-items: center">
          {{
            data.targetCompletionDate
              ? (data.targetCompletionDate | date : "dd/MM/y")
              : "--"
          }}
          <hf-icon-button
            icon="edit"
            [isCircle]="true"
            (click)="onEditTargetCompletionDate()"
            style="margin-left: 16px"
          ></hf-icon-button>
        </div>
      </ng-template>
    </hf-information-box>

    <hf-information-box
      *ngIf="isTargetExchangeDateVisible"
      heading="Target Exchange Date"
    >
      <ng-template #valueTemplate>
        <div style="display: flex; align-items: center">
          {{
            data.targetExchangedDate
              ? (data.targetExchangedDate | date : "dd/MM/y")
              : "--"
          }}
          <hf-icon-button
            icon="edit"
            [isCircle]="true"
            (click)="onEditTargetExchangeDate()"
            style="margin-left: 16px"
          ></hf-icon-button>
        </div>
      </ng-template>
    </hf-information-box>
  </div>

  <div class="boxes-area">
    <hf-information-box
      *ngIf="data?.referralType === 'OFFER_QUALIFICATION'"
      heading="Offer Qualification"
      [actionVisible]="!!qualificationAction"
      [action]="qualificationAction"
      [value]="qualificationValue"
      [lastUpdate]="data?.offerQualification?.lastAction"
      [lastUpdateVisible]="!!data?.offerQualification"
    >
    </hf-information-box>

    <hf-information-box
      *ngIf="data?.productTransferDeclaration"
      heading="PT Declaration"
    >
      <ng-template #valueTemplate>
        <div [hfTooltip]="productTransferDeclarationTooltip">
          {{ productTransferDeclarationValue }}
        </div>
      </ng-template>
    </hf-information-box>

    <div class="empty-box" *ngIf="!isHelpBuyVisible"></div>
  </div>

  <hf-application-list
    [mortgage]="data"
    (createClick)="onCreateApplicationClicked()"
    (statusClick)="onApplicationStatusClicked($event)"
  ></hf-application-list>

  <hf-details-container>
    <hf-details-area>
      <hf-details-row
        label="Heron Ref"
        [text]="data?.yourkeysReferenceId"
      ></hf-details-row>
      <hf-details-row
        label="Category"
        [text]="CLIENT_CATEGORY_NAME.get(data?.clientCategory)"
      >
        <hf-icon-button
          class="change-advisor-btn"
          icon="swap_horizontal_circle"
          (click)="onChangeClientCategoryClicked()"
        ></hf-icon-button>
      </hf-details-row>
      <hf-details-row
        label="First Time Buyer"
        [text]="data?.firstTimeBuyer ? 'Yes' : 'No'"
      ></hf-details-row>
      <hf-details-row
        label="Type"
        [text]="CLIENT_TYPE_NAME.get(data?.clientType)"
      ></hf-details-row>
      <hf-details-row
        label="Scheme"
        [text]="convertEnumToValue(CLIENT_SCHEME, data?.clientScheme)"
      ></hf-details-row>
    </hf-details-area>
    <hf-details-area>
      <hf-details-row
        label="Introducer"
        [text]="data?.introducer?.name"
        [additionalText]="data?.introducerLinkBroken ? '(Unlinked)' : null"
      >
        <hf-icon-button
          class="change-advisor-btn"
          *ngIf="data?.introducer?.internal"
          icon="swap_horizontal_circle"
          (click)="onChangeIntroducerClicked()"
        ></hf-icon-button>
      </hf-details-row>
      <hf-details-row
        [label]="'Region' | wording : data?.introducer?.type"
        [text]="data?.division?.region"
        [additionalText]="data?.introducerLinkBroken ? '(Unlinked)' : null"
      ></hf-details-row>
      <hf-details-row
        [label]="'Division' | wording : data?.introducer?.type"
        [text]="data?.division?.name"
        [additionalText]="data?.introducerLinkBroken ? '(Unlinked)' : null"
      ></hf-details-row>
      <hf-details-row
        label="Sales Advisor"
        [text]="data?.salesAdvisor?.fullName"
        [additionalText]="data?.introducerLinkBroken ? '(Unlinked)' : null"
      >
        <hf-icon-button
          class="change-advisor-btn"
          *ngIf="isChangeSalesAdvisorVisible"
          icon="swap_horizontal_circle"
          (click)="onChangeSalesAdvisorClicked()"
        ></hf-icon-button>
      </hf-details-row>
      <hf-details-row
        label="Advisor"
        [text]="data?.advisor?.fullName"
      ></hf-details-row>
    </hf-details-area>
    <hf-details-area>
      <hf-details-row
        label="Property Value"
        [text]="convertEnumToValue(PROPERTY_VALUE_TYPES, data?.propertyValue)"
      ></hf-details-row>
      <hf-details-row
        label="Referral Type"
        [text]="REFERRAL_TYPE_NAME.get(data?.referralType)"
      ></hf-details-row>
      <hf-details-row
        label="Desired Qualification"
        [text]="
          data?.desiredQualification
            ? (data?.desiredQualification
              | customCurrency : 'GBP' : true : '1.0-2')
            : ' '
        "
      ></hf-details-row>
      <hf-details-row
        [label]="
          data.introducer?.type == 'HOUSE_BUILDER' ? 'Plot Number' : 'Reference'
        "
        [text]="data?.reference"
      ></hf-details-row>
      <hf-details-row
        label="Notes"
        [text]="data?.notes"
        class="notes"
      ></hf-details-row>
    </hf-details-area>
  </hf-details-container>

  <div class="separator"></div>

  <hf-dashboard-general-applicants
    [mortgage]="data"
  ></hf-dashboard-general-applicants>
</section>
